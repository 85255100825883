import React, { useState } from "react";

export default function Accordion({ question, answer }) {
  const [isActive, setIsActive] = useState(false);
  return (
    <div
      className={`border border-black pl-4 pr-[21px] pt-[10px] pb-[11px] w-full duration-1000 ease-in-out transition-maxHeight overflow-hidden ${
        isActive ? "max-h-96" : "max-h-[43px] sm:max-h-[60px]"
      }`}
    >
      <div
        className="relative flex items-center cursor-pointer"
        onClick={() => setIsActive(!isActive)}
      >
        <p className="text-sm sm:text-[20px] md:text-[23px] font-medium leading-[177.4%]">{question}</p>
        <span className="absolute -right-2 sm:right-0 top-[10px] sm:top-4">
          {isActive ? (
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 7L7 1L13 7" stroke="black" />
            </svg>
          ) : (
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M13 1L7 7L1 0.999999" stroke="black" />
            </svg>
          )}
        </span>
      </div>
      <div className="mt-3">
        <p className="text-xs sm:text-[17px] leading-[177.4%]">{answer}</p>
      </div>
    </div>
  );
}
