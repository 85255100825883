/** @format */

import React from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout";
import Heading from "../components/common/heading";
import Button from "../components/common/button";
import NewsLetters from "../components/common/newsletter";
import Accordion from "../components/faq/accordion";
import Seo from "../components/common/SEO";

export default function FAQ({ data }) {
  const { sanityMixFaq } = data;
  return (
    <Layout>
      <Seo
        title={sanityMixFaq?.seo?.metaTitle}
        description={sanityMixFaq?.seo?.metaDescription}
      />
      <div className="pt-[82px]">
        <div className="mix-container">
          <div className="flex flex-col items-center justify-center">
            <Heading
              title={sanityMixFaq?.title}
              caption={sanityMixFaq?.caption}
            />
            <div className="mt-[89px] px-4 lg:px-0 space-y-4 w-full">
              {sanityMixFaq?.faqs.length &&
                sanityMixFaq?.faqs.map((item, index) => {
                  return (
                    <Accordion
                      key={index}
                      answer={item.answer}
                      question={"Q. " + item.question}
                    />
                  );
                })}
            </div>
            <div className="mt-[57px]">
              <Button
                text="READY TO GET MIX"
                type="dark"
                onClick={() => navigate("/products")}
              />
            </div>
          </div>
        </div>
        <div className=" mt-[82px]">
          <NewsLetters small={true} />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityMixFaq {
      title
      caption
      faqs {
        question
        answer
      }
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`;
